<template>
  <MainLayout>
    <v-card class="ma-5">
      <v-card-title>
        <v-icon>mdi-train</v-icon> TRAINS
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Place
              </th>
              <th class="text-left">
                In analyze
              </th>
              <th class="text-left">
                Analyzed
              </th>
              <th class="text-left">
                Wagon count
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="train of trains" :key="train.id">
                <td>{{train.id}}</td>
                <td>{{train.place}}</td>
                <td>
                  <v-icon v-if="train.inAnalyze" color="green">mdi-checkbox-marked-circle</v-icon>
                  <v-icon v-if="!train.inAnalyze" color="red">mdi-cancel</v-icon>
                </td>
                <td>
                  <v-icon v-if="train.analyzed" color="green">mdi-checkbox-marked-circle</v-icon>
                  <v-icon v-if="!train.analyzed" color="red">mdi-cancel</v-icon>
                </td>
                <td>
                  <v-badge
                    :value="train.wagonsCount"
                    :content="train.wagonsCount"
                    bordered
                    color="error"/>
                </td>
                <td>
                  <v-btn depressed color="secondary"
                         @click="goToWagons(train.id, train.uniqueId)">
                    <v-icon class="mr-4">mdi-eye</v-icon> Show wagons
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </MainLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainLayout from '../../components/Layouts/MainLayout.vue';

export default {
  name: 'TrainsPage',
  components: { MainLayout },

  computed: {
    ...mapState('resources', ['places', 'trains']),
  },

  created() {
    this.getTrainsFromApi(this.$route.params.placeId);
  },

  methods: {
    ...mapActions('resources', ['getTrainsFromApi', 'setCurrentTrainUid']),
    goToWagons(trainId, trainUid) {
      this.setCurrentTrainUid(trainUid);
      this.$router.push(
        {
          name: 'wagonsById',
          params: {
            placeId: this.$route.params.placeId,
            trainUid: parseInt(trainUid, 10),
            trainId,
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
